import * as React from "react"
import { BgForContent } from "../components/BgForContent"
import { Layout } from "../components/Layout"
import Seo from "../components/seo"

const TermsPage = () => {
  return (
    <Layout>
      <Seo title="Terms & Conditions" />
      <div className="relative py-16 bg-white overflow-hidden">
        <BgForContent />
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                Help
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Terms & Conditions
              </span>
            </h1>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <h2>OWNERSHIP</h2>
            <p>
              You may not claim intellectual or exclusive ownership rights to
              any of our products. They are provided “as is” without warranty of
              any kind, expressed or implied. We shall not be liable for any
              damages, including but not limited to, direct, indirect, special,
              incidental or consequential damages or losses that occur out of
              the use or inability to use our products.
            </p>
            <h2>SOFTWARE UPDATES</h2>
            <p>
              Updates are included for free for the license time. Therefore if
              you have a valid and active license key or product with free
              license updates will be delivered automatically from the WordPress
              dashboard.
            </p>
            <h2>SUPPORT SERVICES</h2>
            <p>
              Support for purchased software is given during license time and
              only for paid license. Support is provided via email from Monday
              to Friday from 9AM till 5PM in GMT time zone. Once you contact
              support you will receive the first response within the first 24
              hours if it is during business hours.
            </p>
            <h2>SOFTWARE LICENSE OWNERSHIP</h2>
            <p>
              License holders cannot distribute, freely giveaway, or resell
              their license key. The license key is only transferable by
              contacting us and requesting an official transfer of the license
              to a new owner. The license is intended to be used on the license
              holders’ website or websites. Client site usage is permitted in
              situations where the license holder maintains an ongoing
              relationship with the client as a consultant.
            </p>
            <h2>EMAILS</h2>
            <p>
              We may on occasion send you email notifications related to your
              plugin license. These transactional emails may include
              notification of licensing changes, changes to our terms and
              conditions, license expiration notices and other transactional
              emails related to your purchase, questions about using, feedback
              and new our products.
            </p>
            <h2>PUBLICITY & REFERENCES</h2>
            <p>
              We may publicly disclose that we are providing services to you /
              you are using one or more of our plugins and may use your name and
              logo to identify you as our customer in promotional materials,
              including press releases (provided that we allows you to review
              the content prior to issuance) and case studies, white papers,
              event materials, presentations, published media, website content
              and earnings calls, in all media, now known or which shall become
              known in the future.
            </p>
            <h2>WARRANTY</h2>
            <p>
              Our plugins are guaranteed to function on a clean installation
              (vanilla install) of WordPress. We do not and cannot guarantee
              they will function with all third party plugins, extensions,
              themes and web browsers. It is our policy to properly test and
              support our plugins as best we can and we will provide support for
              third party plugin conflicts at our discretion or as time allows.
            </p>
            <h2>LIMITATION OF LIABILITY</h2>
            <p>
              In no event will we, or its suppliers or licencors be liable for
              any direct, indirect, special, incidental or consequential
              damages, including, but not limited to, loss of data or profit,
              arising out of the use, or the inability to use, the product(s)
              and service(s) offered on this website. Even if we or an
              authorized representative has been advised of the possibility of
              such damages. If your use of our product(s) and service(s) from
              this website results in the need for servicing, repair, or
              correction of equipment or data, you assume any costs thereof.
            </p>
            <h2>ACCEPTANCE</h2>
            <p>
              By purchasing our product(s) and service(s) you agree to the Terms
              & Conditions detailed on this page. We reserve the right to change
              or modify the current Terms and Conditions with no prior notice.
              If you have custom requirements or special needs and require a
              personalised Terms & Conditions, please contact us. These terms
              and conditions have last been updated on the 10th December 2021.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TermsPage
